import * as React from "react";
import { Link } from "gatsby";
import classnames from "classnames";
import Arrow from "../../images/arrow.svg";

const ArrowButton = ({
	children,
	className,
	buttonClassName,
	iconClassName,
	url,
	arrow,
	dark,
	...rest
}) => {
	const colorsIconButtonClass = dark
		? "bg-gray group-hover:bg-white"
		: "bg-white group-hover:bg-gray";

	const iconButtonClass = classnames(
		colorsIconButtonClass,
		"h-9 rounded-full aspect-square transition-all flex items-center justify-center ml-1 text-center select-none group-hover:cursor-pointer"
	);

	const colorsIconClass = dark
		? "fill-white group-hover:fill-gray"
		: "fill-gray group-hover:fill-white";

	const iconClass = classnames(
		colorsIconClass,
		"h-4 w-4 inline-block transition-all select-none",
		iconClassName
	);

	return (
		<div className={className}>
			{url ? (
				<Link
					to={url}
					{...rest}
					className="group relative inline-flex items-center"
				>
					<span className={iconButtonClass}>
						<Arrow className={iconClass} />
					</span>
				</Link>
			) : (
				<div className="relative inline-flex items-center" {...rest}>
					<span className={iconButtonClass}>
						<Arrow className={iconClass} />
					</span>
				</div>
			)}
		</div>
	);
};

export default ArrowButton;
