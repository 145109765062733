import React from "react";
import Heading from "./heading";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { getCaseURL } from "../utils/get-asset-url";

const Cta = () => {
	const { email, phone, facebook, instagram, linkedin } = useSiteMetadata();

	const data = useStaticQuery(graphql`
		query {
			directus {
				cases(limit: 5) {
					id
					slug
					title
				}
			}
		}
	`);

	return (
		<section className="bg-white text-white border-l-2 border-b-2 border-l-white md:border-b-0 border-b-off-white">
			<div className="bg-gray py-24 rounded-spark-l">
				<div className="container mx-auto">
					<Heading
						as="h1"
						size="text-4xl md:text-5xl"
						className="mb-8"
					>
						Geïnteresseerd?
					</Heading>
					<p className="text-2xl max-w-4xl">
						Geen uitdaging is voor ons te groot.
					</p>

					<Link
						to="/contact"
						className="border border-white/20 inline-block rounded-full px-4 py-2 my-8"
					>
						<span className="h-3 w-3 relative inline-block mr-4">
							<span className="flex">
								<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-lime-400 opacity-75"></span>
								<span className="relative inline-flex rounded-full h-3 w-3 bg-lime-500"></span>
							</span>
						</span>
						Nu beschikbaar voor opdrachten!
					</Link>

					<div className="grid lg:grid-cols-2 gap-16">
						<div>
							<Heading
								as="h3"
								size="text-2xl md:text-3xl"
								className="mt-16 mb-8"
							>
								Wij maken graag kennis met je!
							</Heading>
							<div className="grid md:grid-cols-2 gap-4">
								<a
									href={`tel:${phone}`}
									className="rounded-lg p-8 bg-muted/25 hover:bg-muted transition-colors"
								>
									<p className="text-lg">{phone}</p>
									<p className="text-xs">
										<span className="w-2 h-2 bg-lime-500 mr-2 inline-block rounded-full ring-2 ring-white align-baseline"></span>
										Bereikbaar ma t/m vr 10u - 16u
									</p>
								</a>
								<a
									href={`mailto:${email}`}
									className="rounded-lg p-8 bg-muted/25 hover:bg-muted transition-colors"
								>
									<p className="text-lg">{email}</p>
									<p className="text-xs">
										<span className="w-2 h-2 bg-sky-500 mr-2 inline-block rounded-full ring-2 ring-white align-baseline"></span>
										Reactie binnen 48 uur!
									</p>
								</a>
							</div>
						</div>
						<div>
							<Heading as="h3" className="mt-16 mb-8">
								Verder lezen
							</Heading>
							<div className="grid md:grid-cols-3 gap-16">
								<div>
									<Heading as="h5" className="mb-3">
										Menu
									</Heading>
									<ul className="space-y-1">
										<li>
											<Link to="/portfolio">
												Portfolio
											</Link>
										</li>
										<li>
											<Link to="/cultuur">Cultuur</Link>
										</li>
										<li>
											<Link to="/contact">Contact</Link>
										</li>
									</ul>
								</div>
								<div>
									<Heading as="h5" className="mb-3">
										Cases
									</Heading>
									<ul className="space-y-1">
										{data.directus.cases.map((item, i) => (
											<li key={item.slug}>
												<Link
													to={getCaseURL(item.slug)}
												>
													{item.title}
												</Link>
											</li>
										))}
									</ul>
								</div>
								<div>
									<Heading as="h5" className="mb-3">
										Volg ons
									</Heading>
									<ul className="space-y-1">
										<li>
											<a
												href={facebook}
												target="_blank"
												rel="noreferrer"
											>
												Facebook
											</a>
										</li>
										<li>
											<a
												href={instagram}
												target="_blank"
												rel="noreferrer"
											>
												Instagram
											</a>
										</li>
										<li>
											<a
												href={linkedin}
												target="_blank"
												rel="noreferrer"
											>
												LinkedIn
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Cta;
