import * as React from "react";
import Layout from "../components/layout";
import { useStaticQuery, graphql } from "gatsby";
import { Seo } from "../components/seo";
import ExtendedTitle from "../components/extended-title";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Cta from "../components/cta";
import Button from "../components/button";

const DronePage = () => {
	const data = useStaticQuery(graphql`
		query DroneQuery {
			directus {
				hero_images(filter: { location: { _eq: "drone" } }) {
					image {
						id
						imageFile {
							childImageSharp {
								gatsbyImageData(width: 1200, quality: 75)
							}
						}
					}
				}
				services_by_id(id: "b6c6c7fd-dd44-4b20-97ae-ad0d5f235377") {
					images {
						directus_files_id {
							imageFile {
								childImageSharp {
									gatsbyImageData(width: 500)
								}
							}
							id
						}
					}
				}
				cases {
					images(limit: 4) {
						directus_files_id {
							id
							imageFile {
								childImageSharp {
									gatsbyImageData(width: 400, quality: 75)
								}
							}
						}
					}
				}
			}
		}
	`);
	return (
		<>
			<Layout>
				<main className="divide-y divide-gray">
					<ExtendedTitle
						backButtonTo="/portfolio"
						backButtonTitle="Bekijk portfolio"
						subtitle="Thunderbirds are go"
						image={data.directus.hero_images[0].image}
					>
						Drone fotografie
					</ExtendedTitle>
					<section className="bg-off-white py-48">
						<div className="max-w-6xl mx-auto px-8">
							<p className="max-w-4xl mx-auto text-xl md:text-2xl leading-normal mb-16 md:text-center font-bold">
								Bij Spark tillen we fotografie naar nieuwe
								hoogten met drone fotografie. Je productie is
								nog waardevoller vastgelegd met goed gebruik van
								drone beelden.
							</p>
							<div className="md:columns-2 text-base gap-16">
								<p className="mb-6">
									Een terreinfoto in vogelvlucht, teamfoto van
									boven, of een panorama van het volledige
									terrein. Door het gebruik van drones tijdens
									producties krijgt de kijker een beter beeld
									van de omvang. Daarnaast geeft het evenement
									direct een grootst (en soms zelf
									internationaal karakter), welk groot
									evenement heeft immers geen beelden van
									boven. Het is een unieke en bijzondere
									positie die anders onbenut blijft. Door de
									combinatie van fotografie aan de grond en
									goed gebruik van fotografie van boven geven
									we onderwerpen geven we een unieke kijk op
									elk aspect van jouw productie of moment.
								</p>
								<p className="mb-6">
									Dankzij hoge kwaliteit raw-foto's die onze
									eigen drones leveren, kunnen we het beeld
									inzetten voor onder andere websites, social
									media, tv-schermen, maar ook prints.
								</p>
								<p className="mb-6">
									Veel onervaren drone piloten zijn niet
									volledig op de hoogte van de actuele regel-
									en wetgeving. Zo heb je te maken met
									veiligheidsrichtlijnen, denk aan no-fly
									zones en regels omtrent bebouwing. Wij zijn
									Europees gecertificeerd voor het maken van
									veilige vluchten. Door onze jarenlange
									ervaring en contacten weten we bijna overal
									een drone in te zetten.
								</p>
							</div>
							<div className="text-center">
								<Button
									to="/contact"
									dark={true}
									className="mt-16 mx-auto"
								>
									Neem contact op
								</Button>
							</div>
						</div>
						<div className="max-w-6xl mx-auto px-8 mt-16">
							<section className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-1 p-1">
								{data.directus.services_by_id.images.map(
									(image, i) => (
										<GatsbyImage
											imgClassName="rounded-lg"
											className="mb-4 object-cover h-full w-auto rounded-lg"
											alt="Spark"
											image={getImage(
												image.directus_files_id
													.imageFile
											)}
										/>
									)
								)}
							</section>
						</div>
					</section>
					<section className="bg-gray text-white text-center h-[50vh] md:h-[70vh] md:min-h-[700px] overflow-hidden relative">
						<div className="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-2 md:gap-4 -rotate-12 absolute origin-top-left -left-1/4">
							{data.directus.cases.map((item, i) =>
								item.images.map((image, i) => (
									<div key={image.directus_files_id.id}>
										<GatsbyImage
											imgClassName="rounded-lg"
											className="mb-4 object-cover h-full w-auto rounded-lg"
											alt="Spark"
											image={getImage(
												image.directus_files_id
													.imageFile
											)}
										/>
									</div>
								))
							)}
						</div>
					</section>
				</main>
				<Cta />
			</Layout>
		</>
	);
};

export default DronePage;

export const Head = () => <Seo title="Drone fotografie - SPARK" />;
